import React, { Component } from "react";
import CircleItem from "../circleItem";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FormattedMessage } from "react-intl";
import SessionManager from "../../utils/authHandling/session-manager";
import {
  DEFAULT_LANGUAGE,
  uploadLink,
  ROLE_STR_ADMIN,
  ROLE_STR_VIDEO_ARTIST,
} from "../../config";
import Dropzone from "react-dropzone-uploader";

class FileDropzone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chosenMedia: "",
      uploadType: "",
      roles:
        SessionManager.getSession() !== null
          ? SessionManager.getSessionDetails().Roles
          : [],
      files: [],
      audioHeaders: [],
      videoHeaders: [],
      submitDisabled: true,
    };
    this.chooseMedia = this.chooseMedia.bind(this);
    this.closeDropzone = this.closeDropzone.bind(this);
    // this.getRules = this.getRules.bind(this);
    this.getUploadParams = this.getUploadParams.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateFiles = this.validateFiles.bind(this);
  }

  //function to verify if user can upload videos
  IsRoleAllowedToUploadVideos = (RoleList) => {
    var allowedRoles = [ROLE_STR_ADMIN, ROLE_STR_VIDEO_ARTIST];
    return allowedRoles.some((role) => RoleList.includes(role));
  };

  chooseMedia(value, type) {
    this.setState({ chosenMedia: value, showDropzone: true, uploadType: type });
  }

  closeDropzone() {
    this.setState({ showDropzone: false });
  }

  getUploadParams = (headers) => {
    console.log("headers", headers);
    //In order to get the response URL: headers.xhr.response NOTE: this object is available when the call ends, it is async
    this.setState({ dropzoneResponseObject: headers });
    if (this.state.chosenMedia === "audio/mp3, audio/ogg, audio/mpeg") {
      let newState = this.state.audioHeaders;
      newState.push(headers);
      this.setState({ audioHeaders: newState });
      //if there are two files then we activate the submit
      if (this.state.audioHeaders.length === 2) {
        this.setState({ submitDisabled: false });
      }
    }
    if (this.state.chosenMedia === "video/mp4, video/ogv, video/ogg") {
      let newState = this.state.videoHeaders;
      newState.push(headers);
      this.setState({ videoHeaders: newState });
      //if there are two files then we activate the submit
      if (this.state.videoHeaders.length === 2) {
        this.setState({ submitDisabled: false });
      }
    }
    return {
      url:
        uploadLink +
        "subject=" +
        this.props.subject +
        "&level=" +
        this.props.grade +
        "&uploadType=" +
        this.state.uploadType +
        "&langCode=" +
        this.props.language,
      headers: {
        Token: SessionManager.getSession(),
        "Accept-Language":
          localStorage.getItem("i18nextLng") == null
            ? DEFAULT_LANGUAGE
            : localStorage.getItem("i18nextLng"),
      },
    };
  };

  //here we can get image size and set a cancelation if the file does not follow the rules.
  handleChangeStatus = (fileWithMeta, status) => {
    console.log("status", status);
    if (status === "done") {
      this.setState({ submitDisabled: false });
    }
    if (status === "error_upload") {
      fileWithMeta.remove();
    }
    if (this.state.chosenMedia === "image/png, image/jpg, image/jpeg") {
      if (fileWithMeta.meta.height > this.props.fileRules[0].MaxHeight) {
        fileWithMeta.cancel();
      }
    }
  };

  handleSubmit = (files, allFiles) => {
    let response = JSON.parse(
      this.state.dropzoneResponseObject.xhr.responseText
    );
    switch (this.state.chosenMedia) {
      case "image/png, image/jpg, image/jpeg":
        if (this.state.removeFile) {
          allFiles.forEach((f) => {
            f.remove();
          });
          alert("File id too large");
        } else {
          this.props.onChange(
            this.props.parentStateKey,
            this.props.content +
              "<img " +
              (this.props.isQuiz ? 'style="max-width: 150px;"' : "") +
              " src='" +
              response.Messages[0] +
              "' />"
          );
        }
        break;
      case "audio/mp3, audio/ogg, audio/mpeg":
        let audioInputString = "";
        this.state.audioHeaders.forEach((item) => {
          let singleResponse = JSON.parse(item.xhr.responseText);
          audioInputString =
            audioInputString +
            '<source src="' +
            singleResponse.Messages[0] +
            '" type="audio/' +
            singleResponse.Messages[0].substr(
              singleResponse.Messages[0].length - 3
            ) +
            '">';
        });
        this.props.onChange(
          this.props.parentStateKey,
          this.props.content +
            "<p><audio controls>" +
            audioInputString +
            "Your browser does not support the audio element.</audio>"
        );
        this.setState({ audioHeaders: [] });
        break;
      case "audio/ogg":
        this.props.onChange(
          this.props.parentStateKey,
          this.props.content +
            '<p><audio controls><source src="' +
            response.Messages[0] +
            '" type="audio/ogg"> Your browser does not support the audio element. </audio></p>'
        );
        break;
      case "video/mp4, video/ogv, video/ogg":
        let videoInputString = "";
        this.state.videoHeaders.forEach((item) => {
          let singleResponse = JSON.parse(item.xhr.responseText);
          videoInputString =
            videoInputString +
            '<source src="' +
            singleResponse.Messages[0] +
            '" type="video/' +
            (singleResponse.Messages[0].substr(
              singleResponse.Messages[0].length - 3
            ) === "ogv"
              ? "ogg"
              : singleResponse.Messages[0].substr(
                  singleResponse.Messages[0].length - 3
                )) +
            '">';
        });
        this.props.onChange(
          this.props.parentStateKey,
          this.props.content +
            '<p><video controls style="width:80%;height:auto;margin-left:10%;">' +
            videoInputString +
            "Your browser does not support the video element.</video>"
        );
        this.setState({ videoHeaders: [] });
        break;
      default:
        //default string to remove warning. this case should never be reached anyway.
        break;
    }
    allFiles.forEach((f) => f.remove());
  };

  validateFiles(droppedFile) {
    let appliedRules = {};
    let messages = [];
    this.props.fileRules.forEach((item) => {
      let typeString = item.Classification + "/" + item.Format;
      if (typeString === droppedFile.meta.type) {
        appliedRules = item;
      }
    });

    if (appliedRules.Classification === "image") {
      if (droppedFile.meta.height > appliedRules.MaxHeight) {
        messages.push("File heigth is too large");
      }
      if (droppedFile.meta.width > appliedRules.MaxWidth) {
        messages.push("File width is too large");
      }
    }
    if (droppedFile.meta.size > appliedRules.MaxSize) {
      messages.push("File size is too large");
    }
    if (messages.length > 0) {
      droppedFile.remove();
      alert(messages);
    }
  }

  render() {
    const roles = this.state.roles,
      baseStyle = {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
      };
    return (
      <div className="card-alt">
        <Container style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <Row>
            <Col
              className={
                this.state.uploadType === "image"
                  ? "dropzone-type-selected"
                  : ""
              }
              style={{ paddingTop: "1rem", paddingBottom: "0.5rem" }}
              onClick={() =>
                this.chooseMedia("image/png, image/jpg, image/jpeg", "image")
              }
            >
              <CircleItem imgSrc="faImage" size="50px">
                <FormattedMessage id="um-image" />
              </CircleItem>
            </Col>
            <Col
              className={
                this.state.uploadType === "audio"
                  ? "dropzone-type-selected"
                  : ""
              }
              style={{ paddingTop: "1rem", paddingBottom: "0.5rem" }}
              onClick={() => this.chooseMedia("audio/mp3, audio/ogg, audio/mpeg", "audio")}
            >
              <CircleItem imgSrc="audio" size="50px">
                <FormattedMessage id="um-audio" />
              </CircleItem>
            </Col>
            {this.IsRoleAllowedToUploadVideos(roles) ? (
              <Col
                className={
                  this.state.uploadType === "video"
                    ? "dropzone-type-selected"
                    : ""
                }
                style={{ paddingTop: "1rem", paddingBottom: "0.5rem" }}
                onClick={() =>
                  this.chooseMedia("video/mp4, video/ogv, video/ogg", "video")
                }
              >
                <CircleItem imgSrc="video" size="50px">
                  <FormattedMessage id="um-video" />
                </CircleItem>
              </Col>
            ) : null}
          </Row>
        </Container>
        {this.state.showDropzone ? (
          <Dropzone
            key="Dropzone View"
            accept={this.state.chosenMedia}
            validate={this.validateFiles}
            timeout={180000}
            submitButtonDisabled={this.state.submitDisabled}
            style={baseStyle}
            getUploadParams={this.getUploadParams}
            onChangeStatus={this.handleChangeStatus}
            onSubmit={this.handleSubmit}
            styles={{ dropzone: baseStyle }}
            inputContent={
              <FormattedMessage id={"u-" + this.state.uploadType} />
            }
          />
        ) : null}
      </div>
    );
  }
}

export default FileDropzone;
