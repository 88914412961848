import React, { Component } from 'react';

import ValidatedTextInput from '../../validatedTextField';
import {Col, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

class CreateSection extends Component{
  
  constructor(props){
    super(props);
    this.state = {
        dataState: this.props.state,
        code: "",
        langCode: "",
        name: "",
        isCreation: typeof this.props.isCreation !== 'undefined' && this.props.isCreation != null ? this.props.isCreation : false,
        infoValidate: typeof this.props.infoValidate !== 'undefined' && this.props.infoValidate != null ? this.props.infoValidate : false,

    };
    this.onChange = this.onChange.bind(this);
  }
  
  onChange(field, value){
    this.setState({[field]: value}, () => { this.props.parentStateHandle(field, value) });
  }
  
  componentWillReceiveProps(nextProps){
    this.setState({
        code: nextProps.state.code,
        langCode: nextProps.state.langCode ? nextProps.state.langCode : "",
        name: nextProps.state.name ? nextProps.state.name : "",
    }, function() {
    });
  }
  
  render(){ 
    const { state } = this.props;
    return(
      <div className="card-alt-2">
          {
              !this.state.isCreation ?
                  <Row className='mb-3'>
                  <Col xs={3} md={2}>
                      <b><FormattedMessage id="sub-code"/></b>
                  </Col>
                  <Col xs={9} md={10}>
                  <b>{this.state.code}</b>
                  </Col>
                  </Row>:
                  <ValidatedTextInput
                      labelID="sub-code"
                      textName="code"
                      onChange={this.onChange}
                      content={state.code}
                      isValidated={state.infoValidate}
                      limitFromRules={10}
                  />
          }

          <ValidatedTextInput
              labelID="sub-lang-code"
              textName="langCode"
              onChange={this.onChange}
              content={state.langCode}
              isValidated={state.infoValidate}
              limitFromRules={2}
          />

          <ValidatedTextInput
              labelID="sub-name"
              textName="name"
              onChange={this.onChange}
              content={state.name}
              isValidated={state.infoValidate}
              limitFromRules={20}
          />
      </div>
    );
  }
}

export default CreateSection;