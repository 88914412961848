import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  ROLE_STR_ADMIN,
} from "../../config";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import CheckboxLessonAddress from "../../components/checkboxLessonAddress";
import DynamicChecklist from "../../components/dynamicChecklist";
import DynamicTable from "../../components/dynamicTable";
import { getStatusTitle } from "./utils";
import { onSubjectCall } from "../../server/getLessonSubjectCall/lesson-subject-call";
import { onGradeCall } from "../../server/getLessonGradesCall/lesson-grade-call";
import { onStatusCall } from "../../server/getLessonStatusCall/lesson-status-call";
import { FaSearch } from "react-icons/fa";
import "./view-lesson.css";
import SpinnerLoader from "../../components/spinnerLoader/spinner-loader";
import SessionManager from "../../utils/authHandling/session-manager";
import ModalConstructorCode from "../viewUnitPage/modal-constructor-code";
import {
  getLessonsQuery,
  getLessonsReport,
} from "../../server/lessonCall/lesson-call";
import Pagination from "@material-ui/lab/Pagination";

class ViewLessonsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showModal: false,
      rowCodes: [],
      subjectsFromServer: [],
      gradesFromServer: [],
      statusFromServer: [],
      selectedGrades: [],
      selectedSubjects: [],
      selectedCodeTypes: [],
      selectedStatus: [],
      tableData: [],
      currentPage: 1,
      totalItems: 0,
      pageSize: 10,
      orderBy: "ASC",
      roles:
        SessionManager.getSession() !== null
          ? SessionManager.getSessionDetails().Roles
          : [],
      codeTypeOpen: false,
      subjectOpen: false,
      gradeOpen: false,
      statusOpen: false,
      projectOpen: false,
      selectedAddresses: [],
    };
    this.onChange = this.onChange.bind(this);
    this.onChangeId = this.onChangeId.bind(this);
    this.toggleSubject = this.toggleSubject.bind(this);
    this.toggleGrade = this.toggleGrade.bind(this);
    this.toggleStatus = this.toggleStatus.bind(this);
    this.toggleProject = this.toggleProject.bind(this);
  }

  componentDidMount() {
    onSubjectCall(this.props.language).then((data) => {
      this.setState({ subjectsFromServer: data });
    });
    onGradeCall(this.props.language).then((data) => {
      this.setState({ gradesFromServer: data });
    });
    onStatusCall().then((data) => {
      this.setState({ statusFromServer: data });
    });
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  onChangeId(event) {
    this.setState({ searchForId: event.target.value });
  }

  handleShowModal = () => {
    this.setState({
      showModal: true,
      codeTypeOpen: true,
    });
  };

  toggleSubject() {
    this.setState({ subjectOpen: !this.state.subjectOpen });
  }

  toggleGrade() {
    this.setState({ gradeOpen: !this.state.gradeOpen });
  }

  toggleStatus() {
    this.setState({ statusOpen: !this.state.statusOpen });
  }

  toggleProject() {
    this.setState({ projectOpen: !this.state.projectOpen });
  }

  onSearchLessons = () => {
    const page = 1;
    this.getLessons(page, this.state.pageSize);
  };

  getLessons = async (page, pageSize) => {
    this.setState({ loading: true });
    page = page - 1;
    try {
      const {
        selectedAddresses,
        selectedSubjects,
        selectedGrades,
        selectedStatus,
        orderBy,
        searchForId,
        rowCodes,
      } = this.state;
      const params = {
        LessonId: searchForId,
        SubjectCodes: selectedSubjects,
        LevelCodes: selectedGrades,
        StatusIds: selectedStatus,
        AddressIds: selectedAddresses,
        CodeIds: rowCodes.map((item) => Number(item.CodeId)),
        PageNumber: page,
        Limit: pageSize,
        OrderBy: orderBy,
      };
      const data = await getLessonsQuery(params);
      const lessons = data.LessonsDetails.Lessons.map((item) => ({
        id: item.LessonModel.Id,
        title: item.LessonModel.LessonTitle,
        subject: item.LessonModel.SubjectCode,
        status: item.CurrentStatusModel.Name,
        definitionQuantity: item.LessonDefinitionModelList.length,
        exampleQuantity: item.LessonExampleModelList.length,
        quizQuantity: item.QuizRequestModelList.length,
        linkItems: [
          {
            lessonId: item.LessonModel.Id,
            link: "./lesson",
          },
          {
            lessonId: item.LessonModel.Id,
            link: "./pdf",
            linkText: "PDF",
          },
        ],
        codes: item.ConstructedCodeHandleModelList.map(item => {
          if (item.MainCode === "1") {
            return `${item.ConstructedCodeFull}*`
          }
          return item.ConstructedCodeFull;
        }),
      }));
      this.setState({
        tableData: lessons,
        totalItems: data.LessonsDetails.TotalResults,
        loading: false,
      });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  onLessonReportCall = async () => {
    this.setState({ loading: true });
    try {
      const {
        selectedAddresses,
        selectedSubjects,
        selectedGrades,
        selectedStatus,
        orderBy,
        searchForId,
        rowCodes,
      } = this.state;
      const params = {
        LessonId: searchForId,
        SubjectCodes: selectedSubjects,
        LevelCodes: selectedGrades,
        StatusIds: selectedStatus,
        AddressIds: selectedAddresses,
        CodeIds: rowCodes.map((item) => Number(item.CodeId)),
        PageNumber: 1,
        Limit: 10,
        OrderBy: orderBy,
      };
      const data = await getLessonsReport(params);
      if (data.Location) {
        let a = document.createElement("a");
        a.href = data.Location;
        a.download = "report.csv";
        a.click();
      }
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleUpdateCode = (vectorCode) => {
    if (vectorCode.length) {
      const row = vectorCode[0];
      if (row) {
        this.setState({
          rowCodes: this.removeDuplicates([...this.state.rowCodes, row]),
        });
      }
      this.setState({
        showModal: false,
      });
    }
  };

  removeDuplicates = (arr) => {
    const arrMap = arr.map((elemento) => {
      return [JSON.stringify(elemento), elemento];
    });
    return [...new Map(arrMap).values()];
  };

  removeItem = (index) => {
    const codes = [...this.state.rowCodes];
    codes.splice(index, 1);
    this.setState({
      rowCodes: codes,
    });
  };

  handleChangePaginate = (event, value) => {
    console.log(value);
    this.setState({
      currentPage: value,
    });
    this.getLessons(value, this.state.pageSize);
  };

  render() {
    return (
      <Container fluid className="card-alt">
        <Row>
          <Col xs={12}>
            <h3>
              <FaSearch />
              <FormattedMessage id="vl-title" />{" "}
            </h3>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md="auto">
            <h4>
              <FormattedMessage id="vl-filters" />
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant={this.state.codeTypeOpen ? "primary" : "outline-primary"}
              onClick={this.handleShowModal}
            >
              <FormattedMessage id="vl-codeType" />
            </Button>{" "}
            <Button
              variant={this.state.subjectOpen ? "primary" : "outline-primary"}
              onClick={this.toggleSubject}
              aria-controls="set-subject-collapse"
              aria-expanded={this.state.subjectOpen}
            >
              <FormattedMessage id="vl-subject" />
            </Button>{" "}
            <Button
              variant={this.state.gradeOpen ? "primary" : "outline-primary"}
              onClick={this.toggleGrade}
              aria-controls="set-grade-collapse"
              aria-expanded={this.state.gradeOpen}
            >
              <FormattedMessage id="vl-grade" />
            </Button>
            <Button
              variant={this.state.statusOpen ? "primary" : "outline-primary"}
              onClick={this.toggleStatus}
              aria-controls="set-status-collapse"
              aria-expanded={this.state.statusOpen}
            >
              <FormattedMessage id="vl-status" />
            </Button>
            <Button
              variant={this.state.projectOpen ? "primary" : "outline-primary"}
              onClick={this.toggleProject}
              aria-controls="set-project-collapse"
              aria-expanded={this.state.projectOpen}
            >
              <FormattedMessage id="vl-project-label" />
            </Button>
          </Col>
        </Row>
        <hr />
        <Collapse in={this.state.codeTypeOpen}>
          <div>
            {this.state.rowCodes.length > 0 && (
              <b>
                <FormattedMessage id="vl-code-label" />:
              </b>
            )}
            {this.state.rowCodes.map((code, i) => (
              <div key={i} className="files-attached-list">
                <FontAwesomeIcon
                  onClick={() => this.removeItem(i)}
                  icon={faTimes}
                />{" "}
                <span className="ml-2">{code.Code}</span>
              </div>
            ))}
          </div>
        </Collapse>
        <Collapse in={this.state.subjectOpen}>
          <div id="set-subject-collapse">
            <b>
              <FormattedMessage id="vl-choose-subject" />
            </b>
            <DynamicChecklist
              items={this.state.subjectsFromServer}
              parentState={this.state.selectedSubjects}
              stateName="selectedSubjects"
              onChange={this.onChange}
              backgroundColor="#007bff"
            />
          </div>
        </Collapse>
        <Collapse in={this.state.gradeOpen}>
          <div id="set-grade-collapse">
            <b>
              <FormattedMessage id="vl-choose-grade" />
            </b>
            <DynamicChecklist
              items={this.state.gradesFromServer}
              parentState={this.state.selectedGrades}
              stateName="selectedGrades"
              onChange={this.onChange}
              backgroundColor="#007bff"
            />
          </div>
        </Collapse>
        <Collapse in={this.state.statusOpen}>
          <div id="set-status-collapse">
            <b>
              {" "}
              <FormattedMessage id="vl-status" />
            </b>
            <DynamicChecklist
              items={this.state.statusFromServer}
              parentState={this.state.selectedStatus}
              stateName="selectedStatus"
              onChange={this.onChange}
              backgroundColor="#007bff"
            />
          </div>
        </Collapse>
        <Collapse in={this.state.projectOpen}>
          <div id="set-project-collapse">
            <CheckboxLessonAddress onChange={this.onChange} />
          </div>
        </Collapse>
        <div>
          <hr />
          <div>
            <b>
              <FormattedMessage id="vl-inputId" />
            </b>
            <Form>
              <Row className="form-group-search">
                <Col>
                  <Form.Control placeholder="ID" onChange={this.onChangeId} />
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={this.onSearchLessons}
                  >
                    {" "}
                    <FaSearch /> <FormattedMessage id="vl-search" />
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        {this.state.loading ? (
          <div style={{ marginTop: "40px" }}>
            <SpinnerLoader />
          </div>
        ) : (
          <div>
            {this.state.totalItems > 0 && (
              <div>
                <hr />
                <div className="tableControlsReverse">
                  {this.state.roles.indexOf(ROLE_STR_ADMIN) > -1 ? (
                    <p>
                      <Button
                        variant="warning"
                        style={{
                          paddingTop: "1px !important",
                          paddingBottom: "1px !important",
                        }}
                        onClick={this.onLessonReportCall}
                      >
                        {" "}
                        <FormattedMessage id="vl-download-archive" />
                      </Button>{" "}
                      <FormattedMessage id="vl-results" /> :{" "}
                      {this.state.totalItems}
                    </p>
                  ) : null}
                </div>
                <DynamicTable
                  data={this.state.tableData}
                  language={this.props.language}
                />
                <div className="pageControls">
                  <Pagination
                    count={
                      Math.ceil(this.state.totalItems / this.state.pageSize)
                    }
                    page={this.state.currentPage}
                    onChange={this.handleChangePaginate}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {this.state.showModal && (
          <Modal show={this.state.showModal} onHide={this.handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <ModalConstructorCode handleUpdateCode={this.handleUpdateCode} />
          </Modal>
        )}
      </Container>
    );
  }
}

export default ViewLessonsPage;
